import PropTypes from 'prop-types';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import config from 'config';
import { makeNullable, optionalRichText, makeRequired, number, slug } from 'lib/validators';
import { useGlobal } from 'context/Global';
import AppBar from 'components/common/AppBar';
import AppBarIconButton from 'components/common/AppBarIconButton';
import AppBarTitle from 'components/common/AppBarTitle';
import AppBarToolbar from 'components/common/AppBarToolbar';
import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import ModalPage from 'components/common/ModalPage';
import Tiles from 'components/common/Tiles';
import RoundedSubnav from 'components/common/RoundedSubnav';
import RoundedSubnavItem from 'components/common/RoundedSubnavItem';
import TeamAdminEditPageOverviewForm from 'components/team-admin/TeamAdminEditPageOverviewForm';
import TeamAdminEditPageProfileForm from 'components/team-admin/TeamAdminEditPageProfileForm';

const teamFragment = gql`
  fragment TeamEditFields on Team {
    id
    name
    avatarImage
    headerImage
    monetaryGoal
    story
    storyTitle
    slug
  }
`;

const GET_CAMPAIGN = gql`
  ${teamFragment}
  query GetCampaign($campaignId: String!, $teamWhere: SequelizeJSON!) {
    findCampaigns(id: $campaignId) {
      id
      teamDefaultStory
      teamDefaultStoryTitle

      teams(where: $teamWhere) {
        ...TeamEditFields
      }
    }
  }
`;

const UPDATE_TEAM = gql`
  ${teamFragment}

  mutation UpdateTeam($team: TeamInput!) {
    updateTeam(Team: $team) {
      ...TeamEditFields
    }
  }
`;

const MESSAGES = config('/validationMessages');

const TeamAdminEditPageModal = ({ teamId, campaignId, onCancel, onComplete }) => {
  const { addToast } = useGlobal();
  const [form, setForm] = useState('overview');
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [isConfirmingNavigation, setIsConfirmingNavigation] = useState(null);

  const validationSchema = yup.object({
    headerImage: yup.string().when('$formView', {
      is: 'overview',
      then: (schema) => makeNullable(schema),
      otherwise: (schema) => schema.strip(),
    }),
    storyTitle: yup.string().when('$formView', {
      is: 'overview',
      then: (schema) => makeNullable(schema),
      otherwise: (schema) => schema.strip(),
    }),
    story: optionalRichText.when('$formView', {
      is: 'overview',
      otherwise: (schema) => schema.strip(),
    }),
    avatarImage: yup.string().when('$formView', {
      is: 'profile',
      then: (schema) => makeNullable(schema),
      otherwise: (schema) => schema.strip(),
    }),
    name: yup.string().when('$formView', {
      is: 'profile',
      then: (schema) => makeRequired(schema),
      otherwise: (schema) => schema.strip(),
    }),
    monetaryGoal: number.when('$formView', {
      is: 'profile',
      then: (schema) => makeRequired(schema.integer().positive(MESSAGES.positiveNumber)),
    }),
    slug: yup.string().when('$formView', {
      is: 'profile',
      then: (schema) =>
        makeNullable(
          slug.uniqueSlug({
            scope: 'team',
            objectId: teamId,
            parentId: campaignId,
          })
        ),
      otherwise: (schema) => schema.strip(),
    }),
  });

  const { data } = useQuery(GET_CAMPAIGN, { variables: { campaignId, teamWhere: { id: teamId } } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const team = useMemo(() => campaign?.teams[0], [campaign]);

  const [updateTeam] = useMutation(UPDATE_TEAM);

  const defaultFormValues = useMemo(
    () => ({
      headerImage: team?.headerImage ?? '',
      storyTitle: team?.storyTitle ?? campaign?.teamDefaultStoryTitle ?? 'Our Mission',
      story: team?.story ?? campaign?.teamDefaultStory,
      avatarImage: team?.avatarImage ?? null,
      name: team?.name ?? '',
      monetaryGoal: team?.monetaryGoal,
      slug: team?.slug ?? '',
    }),
    [team, campaign]
  );

  const formMethods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    context: { formView: form },
  });
  const { reset, handleSubmit, formState } = formMethods;
  const { isDirty, isSubmitting } = formState;

  useEffect(() => reset(defaultFormValues), [reset, defaultFormValues]);

  const onSubmit = useCallback(
    async (values) => {
      await updateTeam({
        variables: {
          team: {
            ...values,
            id: team.id,
          },
        },
      });
      try {
        addToast({
          id: 'edit-team-page',
          type: 'success',
          duration: 'short',
          message: 'Changes have been successfully saved',
        });

        onComplete();
      } catch (err) {
        if (config('/debug')) console.error(err);
        addToast({
          id: 'edit-team-page',
          type: 'error',
          duration: 'long',
          message: 'There was an issue saving your changes. Please try again.',
        });
      }
    },
    [team, updateTeam, addToast, onComplete]
  );

  const onError = useCallback((errors) => {
    const errorFields = Object.keys(errors);
    const profileFields = ['avatarImage', 'name', 'monetaryGoal', 'slug'];

    if (errorFields.every((x) => profileFields.includes(x))) {
      setForm('profile');
      return;
    }

    setForm('overview');
  }, []);

  const onHide = useCallback(() => {
    if (isDirty) {
      setIsConfirmingCancel(true);
      return;
    }

    onCancel();
  }, [isDirty, onCancel, setIsConfirmingCancel]);

  const onSwitchTab = useCallback(
    (tab) => {
      if (isDirty) {
        setIsConfirmingNavigation(tab);
        return;
      }
      setForm(tab);
    },
    [isDirty]
  );

  if (!campaign) return null;

  if (isConfirmingCancel) {
    return (
      <ConfirmDialog
        show={isConfirmingCancel}
        title="You have unsaved changes"
        confirmLabel="Yes, cancel"
        declineLabel="No, go back"
        onConfirm={onCancel}
        onDecline={() => setIsConfirmingCancel(false)}
      >
        <p>Are you sure you want to leave? Your changes will not be saved.</p>
      </ConfirmDialog>
    );
  }

  if (isConfirmingNavigation) {
    return (
      <ConfirmDialog
        show={isConfirmingNavigation}
        title="You have unsaved changes"
        confirmLabel="Yes, continue"
        declineLabel="No, go back"
        onConfirm={() => {
          setForm(isConfirmingNavigation);
          setIsConfirmingNavigation(null);
          reset(defaultFormValues);
        }}
        onDecline={() => setIsConfirmingNavigation(null)}
      >
        <p>Are you sure you want to leave this tab? Your changes will not be saved.</p>
      </ConfirmDialog>
    );
  }

  return (
    <ModalPage show onHide={onHide} className="max-w-lg">
      <AppBar className="z-40">
        <AppBarToolbar>
          <Tiles spacing="sm">
            <AppBarIconButton as="button" type="button" icon={faTimes} onClick={onHide} />
          </Tiles>
          <AppBarTitle as="h1" className="font-medium" title="Edit Page" />
          <Tiles spacing="sm" />
        </AppBarToolbar>
      </AppBar>
      <div className="p-6 md:p-8">
        <div className="text-center mb-3">
          <RoundedSubnav className="inline-block">
            <RoundedSubnavItem
              as="button"
              type="button"
              onClick={() => onSwitchTab('overview')}
              active={form === 'overview'}
            >
              Overview
            </RoundedSubnavItem>

            <RoundedSubnavItem
              as="button"
              type="button"
              onClick={() => onSwitchTab('profile')}
              active={form === 'profile'}
            >
              Profile
            </RoundedSubnavItem>
          </RoundedSubnav>
        </div>
        <FormProvider {...formMethods} context={{ form }}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {form === 'overview' && <TeamAdminEditPageOverviewForm campaignId={campaignId} />}
            {form === 'profile' && <TeamAdminEditPageProfileForm campaignId={campaignId} />}
            <Tiles spacing="sm" align="center" columns={2} className="pt-5">
              <Button
                as="button"
                type="button"
                onClick={() => onCancel(isDirty)}
                color="gray-800"
                className="font-medium w-full"
                outline
              >
                Cancel
              </Button>
              <Button
                as="button"
                type="submit"
                color="primary"
                className="font-medium w-full"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </Tiles>
          </form>
        </FormProvider>
      </div>
    </ModalPage>
  );
};

TeamAdminEditPageModal.propTypes = {
  teamId: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
};

TeamAdminEditPageModal.defaultProps = {
  isAdmin: false,
};

export default TeamAdminEditPageModal;
