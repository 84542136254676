import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

import { useAuth } from 'context/Auth';
import { useCampaignPage } from 'context/CampaignPage';
import useBreakpoint from 'hooks/useBreakpoint';
import redirect from 'lib/redirect';
import CampaignPageSeo from 'components/campaign-page/CampaignPageSeo';
import CampaignPageLayout from 'components/layouts/CampaignPageLayout';
import CampaignPageFundraiserHero from 'components/campaign-page/CampaignPageFundraiserHero';
import CampaignPageFundraiserAvatar from 'components/campaign-page/CampaignPageFundraiserAvatar';
import CampaignPageFundraiserStory from 'components/campaign-page/CampaignPageFundraiserStory';
import CampaignPageFundraiserProgress from 'components/campaign-page/CampaignPageFundraiserProgress';
import CampaignPageTeamInfo from 'components/campaign-page/CampaignPageTeamInfo';
import CampaignPageTeamMembersLeaderboard from 'components/campaign-page/CampaignPageTeamMembersLeaderboard';
import CampaignPageFundraiserContributions from 'components/campaign-page/CampaignPageFundraiserContributions';
import CampaignPageUpdates from 'components/campaign-page/CampaignPageUpdates';
import CampaignPageCustomBlock from 'components/campaign-page/CampaignPageCustomBlock';
import CampaignPageLogoGrid from 'components/campaign-page/CampaignPageLogoGrid';
import CampaignPageSponsors from 'components/campaign-page/CampaignPageSponsors';
import TeamAdminEditPageModal from 'components/team-admin/TeamAdminEditPageModal';

const GET_TEAM = gql`
  query GetTeam($id: String!) {
    findTeams(id: $id) {
      id
      campaign {
        id
        fundraiserWidgets(order: "order") {
          id
          type
          title
          order
          config
        }
      }
      members {
        id
        userId
        isTeamCaptain
      }
    }
  }
`;

const TeamPage = () => {
  const { user } = useAuth();
  const { teamId, campaignId, isEditing, setIsEditing } = useCampaignPage();
  const mobile = !useBreakpoint('lg');

  const { data } = useQuery(GET_TEAM, { variables: { id: teamId } });
  const team = useMemo(() => data?.findTeams[0], [data]);
  const campaign = useMemo(() => team?.campaign, [team]);
  const isTeamCaptain =
    team?.members.filter((x) => x.userId === user?.id && x.isTeamCaptain).length > 0;
  const footerSections = campaign?.fundraiserWidgets ?? [];

  if (!team) return null;

  return (
    <>
      <CampaignPageSeo />

      {isEditing && (
        <TeamAdminEditPageModal
          teamId={teamId}
          campaignId={campaignId}
          onCancel={() => setIsEditing(false)}
          onComplete={() => setIsEditing(false)}
        />
      )}

      {mobile ? (
        <>
          <div className="bg-white border-b border-gray-300 pt-4">
            <div className="container">
              <div className="rounded-2xl overflow-hidden pb-8 -mx-2">
                <CampaignPageFundraiserHero />
                <div className="px-2">
                  <CampaignPageFundraiserProgress />
                  <CampaignPageTeamInfo className="mt-8" />
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-t-2xl bg-white border border-gray-300 mt-20 pt-16 pb-8 relative">
            <CampaignPageFundraiserAvatar className="absolute w-20 h-20 left-1/2 top-0 -translate-1/2" />
            <div className="container">
              <CampaignPageFundraiserStory canEdit={isTeamCaptain} />
            </div>
          </div>
          <div className="container my-12">
            <CampaignPageTeamMembersLeaderboard />
          </div>
          <CampaignPageUpdates className="my-12" />
          <div className="container my-12 relative z-0">
            <CampaignPageFundraiserContributions />
          </div>
        </>
      ) : (
        <div className="container mt-8 mb-12">
          <div className="flex -ml-8">
            <div className="pl-8 w-3/5">
              <div className="rounded-2xl bg-white border border-gray-300 overflow-hidden">
                <CampaignPageFundraiserHero />
                <CampaignPageFundraiserStory className="py-8 px-16" canEdit={isTeamCaptain} />
              </div>
              <CampaignPageTeamMembersLeaderboard className="my-16" />
              <CampaignPageUpdates className="my-8" />
              <CampaignPageFundraiserContributions className="my-16" />
            </div>
            <div className="pl-8 w-2/5">
              <div className="rounded-2xl bg-white  border border-gray-300 overflow-hidden px-12 py-8">
                <CampaignPageFundraiserProgress />
              </div>
              <div className="rounded-2xl bg-white border border-gray-300 overflow-hidden px-12 py-8 mt-4">
                <CampaignPageTeamInfo />
              </div>
            </div>
          </div>
        </div>
      )}
      {footerSections.map((section) => {
        switch (section.type) {
          case 'customBlock': {
            return <CampaignPageCustomBlock key={section.id} id={section.id} />;
          }
          case 'logoGrid': {
            return <CampaignPageLogoGrid key={section.id} id={section.id} />;
          }
          case 'sponsors': {
            return <CampaignPageSponsors key={section.id} id={section.id} />;
          }
          default:
            return null;
        }
      })}
    </>
  );
};

TeamPage.getLayout = (page, router) => (
  <CampaignPageLayout idType="team" id={router.query.id} enableCustomTheme>
    {page}
  </CampaignPageLayout>
);

TeamPage.getInitialProps = async ({ apolloClient, query, res }) => {
  const slugQuery = await apolloClient.query({
    query: gql`
      query GetTeamSlug($id: String!) {
        findTeams(id: $id) {
          id
          slug
          campaign {
            id
            slug
          }
        }
      }
    `,
    variables: { id: query.id },
  });

  const team = slugQuery.data.findTeams[0];
  if (!team) {
    res.statusCode = 404;
    return {};
  }

  // query.teamSlug will already be set if rendering from the slug route
  if (!query.teamSlug && team.slug) {
    const root = team.campaign.slug ? `/${team.campaign.slug}` : `/c/${team.campaign.id}`;
    redirect(res, `${root}/teams/@${team.slug}`);
    return {};
  }

  return {};
};

export default TeamPage;
